<template>
    <td>
        <div class="shop">
            <div class="shop-image">
                <div class="shop-item">
                    <img :src="$media(goods.shopInfo.pic)" alt @error="replaceImage" />
                </div>
            </div>
            <div class="shop-information">
                <div class="title">
                    <span class="mark" :class="getType.goodsName">
                        {{ getType.goodsText }}
                    </span> {{ goods.shopInfo.title }}
                </div>
                <div class="shop-label">
                    <!-- <kdx-tag-label type="warning" size="small"
                        v-if="goods.plugin_identification && goods.plugin_identification.is_commission == '1'">
                        <div>
                            <span class="iconfont icon-fenxiao"></span>
                            <span>
                                分销商品
                            </span>
                        </div>
                    </kdx-tag-label> -->
                    <span v-for="item in goods.shopInfo.category" :key="item">{{ item }}</span>
                </div>
            </div>
        </div>
    </td>
</template>

<script>
export default {
    props: {
        goods: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        getType() {
            // type: 0实体 1虚拟 
            let type;
            switch (this.goods.shopType + '') {
                case '1':
                    type = {
                        goodsName: 'real',
                        goodsText: '实'
                    };
                    break;

                case '2':
                    type = {
                        goodsName: 'virtual',
                        goodsText: '虚'
                    };
                    break;

                // case '2':
                //     type = {
                //         goodsName: 'secret',
                //         goodsText: '密'
                //     };
                //     break;

                // case '4':
                //     type = {
                //         goodsName: 'credit_shop_coupon',
                //         goodsText: '券'
                //     };
                default:
                    type = {};
                    break;
            }
            return type;
        },
    }
};
</script>

<style lang="scss" scoped>
// 商品信息
.shop {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    >.shop-image {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        box-sizing: border-box;
        border: 1px solid $border-color;
        border-radius: 2px;
        flex-shrink: 0;
        overflow: hidden;

        .shop-item {
            width: 80px;
            height: 80px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    >.shop-information {
        flex: 1;
        width: 0;

        >.title {
            display: -webkit-box;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            height: 60px;

            .mark {
                @include font-12-16;
                border-radius: 2px;
                width: 18px;
                height: 18px;
                text-align: center;
                line-height: 18px;
                display: inline-block;

                &.real {
                    background-color: #f0faff;
                    color: $brand-color;
                }

                &.virtual {
                    background-color: #e6fff9;
                    color: #00c5c5;
                }

                &.secret {
                    background-color: #FFCDD0;
                    color: #FF000F;
                }

                &.credit_shop_coupon {
                    background-color: #FFF9E6;
                    color: #FF9900;
                }
            }
        }

        >.shop-label {

            span {
                border-radius: 2px;
                background-color: $background-color;
                @include font-12-16;
                margin-right: 10px;
                color: $text-second;
                display: inline-block;
                height: 20px;
                line-height: 20px;
                padding: 0 8px;
            }

            /deep/ .tag-label {
                margin-bottom: 10px;

                &:first-child {
                    margin-right: 10px;
                }

                .label-warning {
                    span {
                        color: $warning-color;
                    }

                    .iconfont {
                        padding-right: 2px;
                        vertical-align: bottom;
                    }
                }
            }
        }
    }
}
</style>
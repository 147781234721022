import CONFIG from '@/config';
/**
 * 文件导出
 * @description 根据文件地址和文件参数下载导出文件
 * @author Gao
 */

export const Export = (downloadPath = '', params = {}) => {

  let url = downloadPath.indexOf('http') > -1 ? downloadPath : CONFIG.baseUrl + downloadPath;
  url += '?';
  Object.keys(params).forEach(key => {
    url += `${key}=${params[key]}&`;
  });
  window.location.href = url;
};

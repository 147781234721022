<template>
  <div class="order-list" ref="content">
    <search-header ref="search" @on-search="handleSearch" :status="status" @on-export="exportData"
      :timeLabel="status == 'send' ? '下单时间' : status == 'payment' ? '发货时间' : '收货时间'"></search-header>

    <Button v-if="status == 'send'" style="margin-bottom: 10px;" type="primary" @click="handleFh">批量发货</Button>

    <kdx-modal-frame v-model="fhShow" title="批量发货" :width="450" @on-cancel="fhShow = false" @on-ok="fhShow = false"
      :footerHide="true">
      <Form ref="form_info" :label-width="120" class="edit-information-modal" style="padding: 20px 0;">
        <FormItem label="导入：" style="margin-bottom: 0;display: flex">
          <template #label>
            <div>
              <span>导入</span>
              <span>：</span>
            </div>
          </template>
          <Upload :action="$baseUrl + 'shopapi/shop/importsend'" accept=".xls" :max-size="100 * 1024"
            :before-upload="() => showUploadList = true" :show-upload-list="showUploadList"
            :data="{ access_token: $store.state.token }" :on-success="handleSuccess"
            :on-exceeded-size="() => this.$Message.error(`文件大小不能超过100MB`)">
            <Button type="primary">+导入Excel表</Button>
          </Upload>
        </FormItem>
      </Form>
    </kdx-modal-frame>


    <div v-loading="!tableShow">
      <div class="content" v-show="tableShow">
        <order-list ref="table" :checkWidth="checkWidth" :current='page.pageNumber' :pageSize='page.pageSize'
          :data="table.data" :total="table.total" :loading="table.loading" :status="status" @on-page-change="changePage"
          @on-refresh="getList" @on-refresh-head="refresh"></order-list>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import SearchHeader from './components/SearchHeader';
import OrderList from './components/OrderList';
// import qs from 'qs'
import { debounce } from '@/helpers/common';
import { Export } from '@/helpers/export';
export default {
  name: 'index',
  components: {
    SearchHeader,
    OrderList
  },
  data() {
    return {
      showUploadList: true,
      fhShow: false,
      tableShow: false,
      checkWidth: 0,//窗口宽度
      table: {
        data: [],
        loading: false,
        total: 0
      },
      page: {
        pageSize: 10,
        pageNumber: 1
      },
      search: {},
      isExport: '0',
      status: '',

      id: '',
      member_id: '',
      visible: false,
      orderNo: ''
    };
  },
  inject: ['returnToTop'],

  watch: {
    '$route.path': {
      handler() {
        this.status = this.$route.params.id;
        this.$nextTick(() => {
          this.resetSearch();
          this.refreshTable({
            pageSize: 10,
            pageNumber: 1
          });
        });

      },
      immediate: true
    }
  },
  methods: {
    handleSuccess(response, file) {
      const { code, msg, data } = response;
      if (code == 0) {

        this.$Message.success(msg);
      } else {
        this.$Message.error(msg);
      }
      this.showUploadList = false;

      this.refreshTable();
    },
    handleFh() {
      this.fhShow = true;
    },
    exportData() {
      const time = this.search.time ? this.search.time.join(',') : '';
      // this.$api.goods.getGift({ time }, { loading: true, toast: 1 });
      Export('shopapi/shop/getGift', { time, access_token: this.$store.state.token, status: this.status == 'send' ? 0 : this.status == 'payment' ? 1 : 2 });
    },
    refresh: debounce(async function () {
      this.table.loading = true;
      await this.$api.goods.prizeMoneyMember();
      this.refreshTable();
    }, 300),
    handleSearch(search) {
      this.search = JSON.parse(JSON.stringify(search));
      if (this.search.time && this.search.time.length) {
        this.search.time[0] = new Date(this.search.time[0]).getTime() / 1000;
        this.search.time[1] = new Date(this.search.time[1]).getTime() / 1000;
      }
      this.refreshTable();
    },
    resetSearch(search) {
      this.search = Object.assign({}, search);
      this.$nextTick(() => {
        this.$refs['search'].reset(search);
      });
    },
    changePage(page) {
      this.page = page;
      this.getList();
    },
    refreshTable(page) {
      this.page = {
        pageSize: 10,
        pageNumber: 1,
        ...page
      };
      this.getList();
      this.$nextTick(() => {
        this.$refs['table'].reset(page);
      });
    },
    getSendStatus() {
      const { params } = this.$route;
      switch (params.id) {
        case 'all':
          return -1;
        case 'send':
          return 0;
        case 'payment':
          return 1;
        case 'success':
          return 1;
      }
    },
    async getList() {
      this.returnToTop();
      this.table.loading = true;
      let checkWidth = 0;
      checkWidth = this.$refs["content"]?.offsetWidth - 40 - 1;
      if (!checkWidth) {
        setTimeout(() => {
          this.getList();
        }, 30);
        return;
      }
      this.checkWidth = checkWidth;

      const { pageSize: size, pageNumber: page } = this.page;
      const params = { size, page };
      const isSend = this.getSendStatus();
      if (this.status == 'success') {
        params.isSign = 1;
      } else {
        if (isSend != -1) {
          params.isSend = isSend;
        }
        if (isSend == 0) {
          params.isGrant = 1;
        }
      }
      if (this.search.time && this.search.time.length) {
        params.time = this.search.time[0] + ',' + this.search.time[1];
      }

      params[this.search.search_field] = this.search.keywords;

      const result = await this.$api.goods.prizelist(params);
      this.table.data = result.list;
      this.table.total = result.count;


      // this.table.data = [
      //   {
      //     "id": "228",
      //     "status": "10",
      //     "order_no": "1020230511172746829551",
      //     "trade_no": "TR20230511172747935867",
      //     "pay_type": "2",
      //     "goods_price": "7.90",
      //     "activity_type": "0",
      //     "original_goods_price": "7.90",
      //     "scene": "0",
      //     "member_id": "1184",
      //     "member_realname": "",
      //     "member_nickname": "星点Star",
      //     "member_mobile": "13877777777",
      //     "pay_price": "7.90",
      //     "dispatch_price": "0.00",
      //     "dispatch_info": "[null]",
      //     "buyer_remark": "",
      //     "created_at": "2023-05-11 17:27:46",
      //     "order_type": "10",
      //     "create_from": "20",
      //     "send_time": "0000-00-00 00:00:00",
      //     "buyer_name": "炸毛",
      //     "buyer_mobile": "18839276653",
      //     "change_price": "0.00",
      //     "change_dispatch": "0.00",
      //     "dispatch_type": "10",
      //     "extra_price_package": [],
      //     "is_refund": "0",
      //     "refund_type": "0",
      //     "auto_close_time": 1683800866,
      //     "order_goods_id": null,
      //     "refund_status": null,
      //     "order_refund_type": null,
      //     "need_platform": null,
      //     "invoice_info": [],
      //     "extra_discount_rules_package": [],
      //     "seller_remark": "",
      //     "pay_type_text": "余额支付",
      //     "dispatch_type_text": "快递",
      //     "status_text": "待发货",
      //     "create_from_text": "微信公众号",
      //     "type_text": "普通订单",
      //     "icon": {
      //       "electronic_sheet": 1
      //     },
      //     "order_goods": [
      //       {
      //         "id": "231",
      //         "order_id": "228",
      //         "goods_id": "24",
      //         "option_id": "0",
      //         "price": "7.90",
      //         "price_unit": "7.90",
      //         "price_discount": "0.00",
      //         "total": "1",
      //         "title": "旺旺牛奶",
      //         "option_title": "",
      //         "thumb": "image/2023/05/efba56de320996e308b50835e92389d6.jpg",
      //         "is_single_refund": "0",
      //         "refund_status": "0",
      //         "refund_type": "0",
      //         "package_id": "0",
      //         "package_cancel_reason": "",
      //         "goods_sku": "",
      //         "plugin_identification": {
      //           "is_commission": "1"
      //         },
      //         "shop_goods_id": "0",
      //         "shop_option_id": "0",
      //         "ext_field": {
      //           "show_sales": "0",
      //           "show_stock": "1",
      //           "invoice": "0",
      //           "refund": "1",
      //           "return": "1",
      //           "exchange": "1",
      //           "is_delivery_pay": "0",
      //           "auto_putaway": "0",
      //           "single_max_buy": "",
      //           "single_min_buy": "",
      //           "max_buy": "",
      //           "putaway_time": "",
      //           "note": "",
      //           "buy_button_settings": [],
      //           "buy_button_type": "0",
      //           "buy_limit": "0"
      //         },
      //         "is_count": "1",
      //         "type": "0",
      //         "has_option": "0",
      //         "refund_status_text": ""
      //       }
      //     ],
      //     "goods_count": 1
      //   },
      //   {
      //     "id": "226",
      //     "status": "20",
      //     "order_no": "1020230511171938717426",
      //     "trade_no": "",
      //     "pay_type": "0",
      //     "goods_price": "0.01",
      //     "activity_type": "0",
      //     "original_goods_price": "0.01",
      //     "scene": "0",
      //     "member_id": "1182",
      //     "member_realname": "",
      //     "member_nickname": "Whaoe",
      //     "member_mobile": "",
      //     "pay_price": "0.00",
      //     "dispatch_price": "0.00",
      //     "dispatch_info": "[null]",
      //     "buyer_remark": "",
      //     "created_at": "2023-05-11 17:19:38",
      //     "order_type": "10",
      //     "create_from": "20",
      //     "send_time": "0000-00-00 00:00:00",
      //     "buyer_name": "炸毛",
      //     "buyer_mobile": "13245678910",
      //     "change_price": "0.00",
      //     "change_dispatch": "0.00",
      //     "dispatch_type": "10",
      //     "extra_price_package": {
      //       "balance": 0.01
      //     },
      //     "is_refund": "0",
      //     "refund_type": "0",
      //     "auto_close_time": 1683800378,
      //     "order_goods_id": null,
      //     "refund_status": null,
      //     "order_refund_type": null,
      //     "need_platform": null,
      //     "invoice_info": [],
      //     "extra_discount_rules_package": [
      //       {
      //         "balance": {
      //           "goods_id": "1",
      //           "option_id": "0",
      //           "price": 0.01
      //         }
      //       }
      //     ],
      //     "seller_remark": "",
      //     "pay_type_text": "无需支付",
      //     "dispatch_type_text": "快递",
      //     "status_text": "待发货",
      //     "create_from_text": "微信公众号",
      //     "extra_price_package_text": {
      //       "余额抵扣": 0.01
      //     },
      //     "type_text": "普通订单",
      //     "icon": {
      //       "electronic_sheet": 1
      //     },
      //     "order_goods": [
      //       {
      //         "id": "229",
      //         "order_id": "226",
      //         "goods_id": "1",
      //         "option_id": "0",
      //         "price": "0.00",
      //         "price_unit": "0.01",
      //         "price_discount": "0.01",
      //         "total": "1",
      //         "title": "格兰仕 20升 快捷家用 六档火力旋钮 易清洁 小型迷你微波炉P70D20TL-D4 1-2人适用",
      //         "option_title": "",
      //         "thumb": "image/2022/08/6d855e43ff9231b0ee8b58c6322714b4.png",
      //         "is_single_refund": "0",
      //         "refund_status": "0",
      //         "refund_type": "0",
      //         "package_id": "0",
      //         "package_cancel_reason": "",
      //         "goods_sku": "",
      //         "plugin_identification": {
      //           "is_commission": "0"
      //         },
      //         "shop_goods_id": "0",
      //         "shop_option_id": "0",
      //         "ext_field": {
      //           "show_sales": "0",
      //           "show_stock": "1",
      //           "invoice": "0",
      //           "refund": "1",
      //           "return": "1",
      //           "exchange": "1",
      //           "is_delivery_pay": "0",
      //           "auto_putaway": "0",
      //           "single_max_buy": "",
      //           "single_min_buy": "",
      //           "max_buy": "",
      //           "putaway_time": "",
      //           "note": "",
      //           "buy_button_settings": [],
      //           "buy_button_type": "0",
      //           "buy_limit": "0"
      //         },
      //         "is_count": "1",
      //         "type": "0",
      //         "has_option": "0",
      //         "refund_status_text": ""
      //       }
      //     ],
      //     "goods_count": 1
      //   }, {
      //     "id": "222",
      //     "status": "30",
      //     "order_no": "1020230511172746829551",
      //     "trade_no": "TR20230511172747935867",
      //     "pay_type": "2",
      //     "goods_price": "7.90",
      //     "activity_type": "0",
      //     "original_goods_price": "7.90",
      //     "scene": "0",
      //     "member_id": "1184",
      //     "member_realname": "",
      //     "member_nickname": "星点Star",
      //     "member_mobile": "13877777777",
      //     "pay_price": "7.90",
      //     "dispatch_price": "0.00",
      //     "dispatch_info": "[null]",
      //     "buyer_remark": "",
      //     "created_at": "2023-05-11 17:27:46",
      //     "order_type": "10",
      //     "create_from": "20",
      //     "send_time": "0000-00-00 00:00:00",
      //     "buyer_name": "炸毛",
      //     "buyer_mobile": "18839276653",
      //     "change_price": "0.00",
      //     "change_dispatch": "0.00",
      //     "dispatch_type": "10",
      //     "extra_price_package": [],
      //     "is_refund": "0",
      //     "refund_type": "0",
      //     "auto_close_time": 1683800866,
      //     "order_goods_id": null,
      //     "refund_status": null,
      //     "order_refund_type": null,
      //     "need_platform": null,
      //     "invoice_info": [],
      //     "extra_discount_rules_package": [],
      //     "seller_remark": "",
      //     "pay_type_text": "余额支付",
      //     "dispatch_type_text": "快递",
      //     "status_text": "待发货",
      //     "create_from_text": "微信公众号",
      //     "type_text": "普通订单",
      //     "icon": {
      //       "electronic_sheet": 1
      //     },
      //     "order_goods": [
      //       {
      //         "id": "231",
      //         "order_id": "228",
      //         "goods_id": "24",
      //         "option_id": "0",
      //         "price": "7.90",
      //         "price_unit": "7.90",
      //         "price_discount": "0.00",
      //         "total": "1",
      //         "title": "旺旺牛奶",
      //         "option_title": "",
      //         "thumb": "image/2023/05/efba56de320996e308b50835e92389d6.jpg",
      //         "is_single_refund": "0",
      //         "refund_status": "0",
      //         "refund_type": "0",
      //         "package_id": "0",
      //         "package_cancel_reason": "",
      //         "goods_sku": "",
      //         "plugin_identification": {
      //           "is_commission": "1"
      //         },
      //         "shop_goods_id": "0",
      //         "shop_option_id": "0",
      //         "ext_field": {
      //           "show_sales": "0",
      //           "show_stock": "1",
      //           "invoice": "0",
      //           "refund": "1",
      //           "return": "1",
      //           "exchange": "1",
      //           "is_delivery_pay": "0",
      //           "auto_putaway": "0",
      //           "single_max_buy": "",
      //           "single_min_buy": "",
      //           "max_buy": "",
      //           "putaway_time": "",
      //           "note": "",
      //           "buy_button_settings": [],
      //           "buy_button_type": "0",
      //           "buy_limit": "0"
      //         },
      //         "is_count": "1",
      //         "type": "0",
      //         "has_option": "0",
      //         "refund_status_text": ""
      //       }
      //     ],
      //     "goods_count": 1
      //   },
      // ];
      this.table.loading = false;
      this.tableShow = true;
    },


  },
  beforeDestroy() {

  },
  mounted() {


  }
};

</script>

<style scoped lang="scss">
.order-list {
  margin: 20px auto;
  background-color: $background-color;

  .content {
    background-color: #ffffff;
    border-radius: 2px;
    /*margin-bottom: 20px;*/
  }
}
</style>

<template>
    <div>
        <!--下单时间等等-->
        <div class="table-content-time">
            <div class="left">
                <span class="text">创建时间：{{ new Date(item.createTime * 1000) | dateFormat  }}</span>
                <span class="text1">订单编号：{{ item.orderNum }}</span>
                <kdx-copy-text :text="item.orderNum" v-if="item.orderNum">
                    <Button type="text" size="small">复制</Button>
                </kdx-copy-text>

            </div>
            <div class="right">
                <!-- <span @click="jumpEOrder(item.order_no)" v-if="hasOrderSheet(item) && !item.not_show_express">发货单</span>
                <span @click="jumpEOrder(item.order_no)"
                    v-if="hasOrderSheet(item) && !item.not_show_express && virtualGood(item)">电子面单</span>
                <span type="text" @click="handlePrinter(item.id)">打印小票</span>
                <span class="remaining-time"
                    v-if="item.status === '0' && item.auto_close_time * 1000 - Date.now() > 0 && countTime">
                    {{ countTime }}
                </span> -->

                <!--订单详情-->
                <span @click="checkDetail(item.id, item.isSend)">订单详情</span>
                <!-- 备注 -->
                <!-- <Button type="text" :class="{
                    'remark-on': item.seller_remark,
                }" @click="handlerRemark">备注
                </Button> -->
            </div>
            <kdx-modal-frame v-model="printerModal" title="请选择相关信息" :width="610" @on-cancel="printerCancel"
                @on-ok="printerOk">
                <div class="printer-modal">
                    <Form ref="form" :label-width="120" :model="printerModelData" :rules="rules" @submit.native.prevent>
                        <FormItem label="打印机：" prop="printer_id">
                            <Select class="width-340" v-model="printerModelData.printer_id" placeholder="请选择打印机">
                                <Option v-for="item in printerList" :key="item.id" :value="item.id">{{ item.name }}
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem label="打印模板：" prop="template_id">
                            <Select class="width-340" v-model="printerModelData.template_id" placeholder="请选择打印模板">
                                <Option v-for="item in templateList" :key="item.id" :value="item.id">{{ item.name }}
                                </Option>
                            </Select>
                        </FormItem>
                    </Form>
                </div>
            </kdx-modal-frame>

            <!-- 商家备注 -->
            <kdx-modal-frame v-model="remarkModal" title="商家备注" :width="520" @on-cancel="remarkModalClose"
                @on-ok="remarkModalOk">
                <div class="remark-modal">
                    <Form>
                        <FormItem>
                            <Input type="textarea" placeholder="请输入" maxlength="200" :autosize="{ minRows: 4 }"
                                show-word-limit v-model="remarkText" />
                        </FormItem>
                    </Form>
                </div>
            </kdx-modal-frame>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        countTime: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            printerList: [],
            templateList: [],
            printerModal: false,
            printerModelData: {
                printer_id: '',
                template_id: '',
                order_id: '',
            },
            rules: {
                printer_id: [
                    { required: true, message: '请输入打印机名称' }
                ],
                template_id: [
                    { required: true, message: '请选择打印模板' }
                ],
            },
            remarkText: '',
            remarkModal: false,

        };
    },
    methods: {
        // 跳转电子面单打印
        jumpEOrder(order_no) {
            let url = `/eOrder/orderPrint/index?order_no=${order_no}`;
            this.$utils.openNewWindowPage(url);
        },
        // 查看详情
        checkDetail(id, status) {
            let path = "";
            switch (status * 1) {
                case 0:
                    path = "/main/order/detail/send";
                    break;
                case 1:
                    path = "/main/order/detail/achieve";
                    break;
            }
            // if (status == "10" || status == "11") {
            //     path = "/main/order/detail/send";
            // } else if (status == "0") {
            //     path = "/main/order/detail/payment";
            // } else if (status == "20") {
            //     path = "/main/order/detail/receive";
            // } else if (status == "30") {
            //     path = "/main/order/detail/achieve";
            // } else if (status == "-1") {
            //     path = "/main/order/detail/close";
            // }
            this.$router.push({
                path,
                query: {
                    id
                }
            });
        },
        // 打印小票
        handlePrinter(id) {
            this.$api.printerApi.printerList({ only_list: 1, status: 1 }).then(res => {
                if (res.error == 0) {
                    if (res.data.length <= 0) {
                        this.toPrinterList();
                    } else {
                        this.printerList = res.data;
                        this.checkPrinter(id);
                    }
                }
            });
        },
        toPrinterList() {
            this.$Modal.confirm({
                title: '提示',
                content: '您当前还未配置打印机，立即前往进行配置。',
                okText: '立即配置',
                cancelText: '暂不配置',
                onOk: () => {
                    this.$utils.openNewWindowPage('/printer/manage/list');
                },
                onCancel: () => {

                }
            });
        },
        getPrinterTemList() {
            this.$api.printerApi.getPrinterTemList({ only_list: 1 }).then(res => {
                if (res.error == 0) {
                    this.templateList = res.data;
                    this.printerModal = true;
                }
            });
        },
        checkPrinter(id) {
            this.$api.printerApi.executePrinter({ order_id: id, is_check: 1 }).then(res => {
                if (res.error == 0) {
                    this.printerModelData.order_id = id;
                    if (res?.message > 0) {
                        this.checkPrinterNum(res.message);
                    } else {
                        this.getPrinterTemList();
                    }
                }
            });
        },
        checkPrinterNum(num) {
            this.$Modal.confirm({
                title: '提示',
                content: `您已经打印${num}次，是否继续打印`,
                okText: '立即打印',
                cancelText: '暂不打印',
                onOk: () => {
                    this.getPrinterTemList();
                },
                onCancel: () => {

                }
            });
        },
        printerCancel() {
            this.printerModal = false;
        },
        printerOk() {
            this.$refs['form'].validate(valid => {
                if (valid) {
                    this.$api.printerApi.executePrinter(this.printerModelData).then(res => {
                        if (res.error == 0) {
                            this.$Message.success('打印成功');
                        }
                        this.printerModal = false;
                    });
                }
            });
        },
        // 备注操作
        handlerRemark() {
            this.remarkModal = true;
            this.remarkText = this.item.seller_remark;
        },
        remarkModalClose() {
            this.remarkModal = false;
        },
        remarkModalOk() {
            this.$api.orderApi.setOrderRemark({ order_id: this.item.id, seller_remark: this.remarkText }).then(res => {
                if (res.error == 0) {
                    this.$Message.success('保存成功');
                    this.remarkModal = false;
                    this.$emit('on-refresh');
                }
            });

        },
        // 判断显示发货单和电子面单
        hasOrderSheet(item) {
            return item.icon?.electronic_sheet === 1;
        },
        // 判断商品类型
        virtualGood(item) {
            return !(item.order_goods[0]?.type == 1);
        },
    }
};
</script>

<style lang="scss" scoped>
// 下单时间
.table-content-time {
    &.control {
        background: #f00;
    }

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    @include font-14-20;
    background-color: $background-color;
    height: 50px;
    border-right: 1px solid $border-color;

    >.left {
        .icon {
            font-size: 24px;
            margin-right: 4px;
            vertical-align: middle;
        }

        .icon-PCduan {
            color: #12aa9c;
            font-size: 20px;
            margin-right: 5px;
            position: relative;
            top: 3px;
        }

        .icon-weixin {
            color: $success-color;
        }

        .icon-weixinxiaochengxu {
            color: #677de0;
        }

        .icon-toutiaoxiaochengxu {
            color: #239bff;
        }

        .icon-zhifubaoxiaochengxu {
            color: #2094e7;
        }

        .icon-baiduxiaochengxu {
            color: #306cff;
        }

        .icon-H {
            color: #ff6004;
        }

        >.text {
            padding-right: 20px;
            color: $text-third;
        }

        >.text1 {
            padding-right: 10px;
            color: $text-third;
        }

        .activity-icon {
            display: inline-block;
            margin-right: 4px;
            width: 24px;
            height: 24px;
            padding: 2px;
            vertical-align: middle;

            .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                color: #fff;
                text-align: center;
                border-radius: 50%;
            }


            .seckill,
            .credit,
            .groups {
                margin-right: 4px;
                font-size: 24px;
            }

            .seckill {
                color: #ff1f2c;
            }

            .credit {
                color: #6100FF;
            }

            .groups {
                color: #F66000;
            }
        }
    }

    >.right {
        display: flex;
        align-items: center;

        span {
            padding-left: 10px;
            padding-right: 10px;
            /* border-right: 1px solid #B8B9BD; */
            color: $brand-color;
            cursor: pointer;
        }

        >.remaining-time {
            color: $danger-color;
            cursor: auto;
        }

        /deep/ .ivu-btn {
            margin-left: 10px;
        }

        .remark-on {
            /deep/ span {
                color: #FF1F2C;
            }
        }
    }
}

.printer-modal {
    padding: 30px 0;
}

.remark-modal {
    padding: 40px;
}

.tip-wp {
    padding-left: 22px;

    * {
        vertical-align: middle;
    }

    $rz: 20px;

    img {
        width: $rz;
        height: $rz;
        border-radius: 41px;
        margin-right: 6px;
    }

    .tith {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        display: inline-block;
        width: 70px;
        font-size: 14px;
        line-height: 20px;
        color: #939799;
    }
}
</style>

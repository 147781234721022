<template>
	<div class="order-table-list" v-loading="loading">
		<div class="order-table-list-box" ref="order_table">
			<div class="table-header">
				<table :style="{ width: `${tableWidth || checkWidth}px` }" style="border-collapse:collapse">
					<colgroup align="left">
						<col v-for="(column, index) in columns" :key="index + '_' + column.width" :width="column.width" />
					</colgroup>
					<tr>
						<th v-for="(column, index) in columns" :key="index">
							<div class="tab-cell-last" v-if="index == columns.length - 1">
								<span>{{ column.title }}</span>
								<Button size="small" type="default" icon="md-refresh" @click="refresh">更新状态</Button>
							</div>
							<div v-else class="table-cell">{{ column.title }}</div>
						</th>
					</tr>
				</table>
			</div>
			<template v-if="data.length > 0">
				<div v-for="list in data" :key="list.id" class="table-content">
					<!--下单时间等等-->
					<OrderListItemHead :item="list" :count-time="list.grantTime" @on-refresh="handleRefresh">
					</OrderListItemHead>
					<!--订单信息-->
					<div class="table-order">
						<table :style="{ width: `${tableWidth || checkWidth}px` }" style="border-collapse:collapse">
							<colgroup align="left">
								<col v-for="(column, index) in columns" :key="index + '_' + column.width" :width="column.width" />
							</colgroup>
							<!--合并单元格-->
							<tr>
								<!--商品信息-->
								<TableTd1 :goods="list"></TableTd1>
								<td>
									<div class="brand">
										<span v-for="item in list.shopInfo.brand" :key="item">{{ item }}</span>
										<template v-if="list.shopInfo.brand.length == 0">-</template>
									</div>
								</td>
								<td>
									<div class="multi">
										<div class="shop-image" v-if="list.shopType == 1 && list.packageInfo.pic">
											<div class="shop-item">
												<img :src="$media(list.packageInfo.pic)" alt @error="replaceImage" />
											</div>
										</div>
										<div class="price">{{ list.packageInfo.title }}</div>
									</div>

								</td>

								<!--商品价格、数量-->
								<td>
									<div class="shop-price-number price">
										<!-- 其他普通商品单价 -->
										<p>￥{{ list.money }}</p>
										<!-- <p>X {{ goods.total }}</p> -->
									</div>
								</td>

								<!--买家-->
								<td :rowspan="1">
									<div class="shop-buyer buyer" v-if="list.name || list.mobile">
										<p>{{ list.name }}</p>
										<p>{{ list.mobile }}</p>
									</div>
									<template v-else>-</template>
								</td>
								<!--实付款-->
								<!-- <TableTd5 :list="list" :goods="goods"></TableTd5> -->
								<!--支付、配送-->
								<td :rowspan="1">
									<div class="shop-payment delivery">
										<p>{{ list.logisticsNme }}</p>
										<p>{{ list.number }}</p>
									</div>
								</td>
								<!--操作-->
								<TableTd7 :list="list" :goods="list" :status="status" @sendGoods="sendGoods"
									@editReceiveInfo="editReceiveInfo" @generalRefund="generalRefund" @cancelSendGoods="cancelSendGoods"
									@editExpress="editExpress" @handleRefresh="handleRefresh" @changePrice="changePrice"
									@packageExpress="packageExpress"></TableTd7>
							</tr>
						</table>
					</div>
				</div>
			</template>
			<div v-else style="text-align: center;padding: 20px 0">暂无数据</div>
		</div>
		<div class="table-footer">
			<kdx-page-component ref="page" :current='current' :pageSize='pageSize' :total="total"
				@on-change="handlePageChange"></kdx-page-component>
		</div>
		<!--确认发货-->
		<send-goods ref="send_goods" :item="modal" @on-refresh="handleRefresh"></send-goods>
		<!--分包裹---取消发货-->
		<cancel-send-goods ref="cancel_send_goods" :id="modal.id" @on-refresh="handleRefresh"></cancel-send-goods>
		<!--分包裹---修改快递-->
		<edit-express ref="edit_express" :id="modal.id" :package-id="modal.packageId"
			@on-refresh="handleRefresh"></edit-express>
		<!--订单改价-->
		<change-price ref="change_price" :id="modal.id" @on-refresh="handleRefresh"></change-price>
		<!--关闭订单-->
		<close-order ref="close_order" :visible="modal.closeOrder" :id="modal.id" :title="modal.title"
			@on-refresh="handleRefresh"></close-order>
		<!--查看物流-->
		<package-express ref="package_express" :id="modal.id" :package-id="modal.packageId"></package-express>
		<!--修改收货信息-->
		<edit-receiving-goods ref="edit_receiving_goods" :id="modal.id" :dispatch_type="modal.dispatch_type"
			@on-refresh="handleRefresh"></edit-receiving-goods>
		<!--退款-->
		<refund-modal ref="refund_modal" :id="modal.id" :pay_type="modal.pay_type" :good_type="modal.good_type"
			@on-refresh="handleRefresh"></refund-modal>
	</div>
</template>

<script>
import SendGoods from "@/views/order/components/SendGoods/index";
import CancelSendGoods from "@/views/order/components/CancelSendGoods/index";
import EditExpress from "@/views/order/components/EditExpress/index";
import ChangePrice from "@/views/order/components/ChangePrice/index";
import CloseOrder from "@/views/order/components/CloseOrder/index";
import PackageExpress from "@/views/order/components/PackageExpress/index";
import EditReceivingGoods from "@/views/order/components/EditReceiveGoods/index";
import RefundModal from "@/views/order/components/RefundModal";
import OrderListItemHead from "./OrderListItemHead";
import TableTd1 from "./OrderListTd1";

// import TableTd5 from "./OrderListTd5";
import TableTd7 from "./OrderListTd7";

import { debounce } from "@/helpers/common";
export default {
	name: "OrderList",
	components: {
		SendGoods,
		CancelSendGoods,
		EditExpress,
		ChangePrice,
		CloseOrder,
		PackageExpress,
		EditReceivingGoods,
		RefundModal,
		OrderListItemHead,
		TableTd1,

		// TableTd5,
		TableTd7,
	},
	props: {
		data: {
			type: Array,
			default: () => []
		},
		total: {
			type: Number,
			default: 0
		},
		loading: {
			type: Boolean,
			default: false
		},
		// 每页多少条
		pageSize: {
			type: Number,
			default: 10
		},
		// 当前页码
		current: {
			type: Number,
			default: 1
		},
		status: {
			type: [Number, String]
		},
		checkWidth: {
			type: [Number, String]
		},
	},
	data() {
		return {
			columns: [
				{
					title: "商品",
					key: "shop",
					width: 250
				},
				{
					title: "品牌",
					key: "brand",
					width: 90
				},
				{
					title: "所属套餐",
					key: "multi",
					width: 210
				},
				{
					title: "单价",
					key: "price",
					width: 100
				},
				{
					title: "收货人",
					key: "buyer",
					width: 100
				},
				// {
				// 	title: "实付款",
				// 	key: "payment",
				// 	width: 100
				// },
				{
					title: "物流",
					key: "delivery",
					width: 150
				},
				{
					title: "操作",
					key: "action",
					width: 100
				}
			],
			cloneColumns: [],
			totalWidth: 0,
			tableWidth: 0,
			page: {
				total: 10
			},
			modal: {
				cancelSendGoodsModal: 0,
				editExpress: 0,
				changePrice: 0,
				closeOrder: 0,
				packageExpress: 0,
				title: "",
				id: "",
				packageId: "",
				dispatch_type: ""
			},
			commisionInfo: {}
		};
	},
	methods: {
		refresh() {
			this.$emit("on-refresh-head");
		},
		// 动态改变表格的width
		handleResize: debounce(function () {
			// 40 padding 2 border
			this.tableWidth = this.$refs["order_table"]?.offsetWidth - 40 - 1;
			let columnLength = this.columns.length;
			let columnWidth = Math.floor(
				(this.tableWidth - this.totalWidth) / columnLength
			);
			this.columns.forEach((item, index) => {
				let width = (this.cloneColumns[index].width || 0) + columnWidth;
				// if (index === columnLength - 1) {
				// 	let totalWidth = this.getUpdateTotalWidth();
				// 	width = this.tableWidth - totalWidth + 100 || 0;
				// }
				this.$set(this.columns[index], "width", width);
			});
		}, 300),
		// 深拷贝columns
		getCloneColumns() {
			this.cloneColumns = this.columns.map(item =>
				JSON.parse(JSON.stringify(item))
			);
		},
		// 获取columns的默认总宽度
		getTotalWidth() {
			this.totalWidth = 0;
			this.cloneColumns.forEach(item => {
				this.totalWidth += item.width || 0;
			});
		},
		// getUpdateTotalWidth() {
		getUpdateTotalWidth() {
			let totalWidth = 0;
			this.columns.forEach(item => {
				totalWidth += item.width || 0;
			});
			return totalWidth;
		},
		handlePageChange(page) {
			this.$emit("on-page-change", page);
		},
		// 确认发货
		sendGoods(row) {
			this.modal = row;
			this.$nextTick(() => {
				console.log('this.$refs["send_goods"]', this.$refs["send_goods"]);
				this.$refs["send_goods"]?.setValue();
			});
		},
		// 取消发货
		cancelSendGoods(row) {
			// 分包裹取消
			this.modal.id = row.id;
			this.$nextTick(() => {
				this.$refs["cancel_send_goods"].setValue();
			});
		},
		// 修改收货信息
		editReceiveInfo(row) {
			this.modal.id = row.id;
			this.modal.dispatch_type = row.dispatch_type;
			this.$nextTick(() => {
				this.$refs["edit_receiving_goods"].setValue();
			});
		},
		// 退款
		generalRefund(row) {
			this.modal.id = row.id;
			this.$nextTick(() => {
				this.$refs["refund_modal"].setValue();
			});
		},
		// 修改物流
		editExpress(row) {
			this.modal.id = row.id;
			if (this.data.status === "11") {
				this.modal.packageId = row.package_id;
			} else {
				this.modal.packageId = "";
			}
			this.$nextTick(() => {
				this.$refs["edit_express"].setValue();
			});
		},
		// 订单改价
		changePrice(row) {
			this.modal.id = row.id;
			this.$nextTick(() => {
				this.$refs["change_price"].setValue();
			});
		},
		// 物流信息
		packageExpress(row) {
			this.modal.id = row.id;
			this.modal.packageId = row.order_goods[0].package_id;
			this.$nextTick(() => {
				this.$refs["package_express"].setValue();
			});
		},
		reset(page) {

			this.$refs["page"]?.reset(page);
		},
		handleRefresh() {
			this.$emit("on-refresh");
		},
		cellStatus({ is_refund, refund_type }, index) {
			if (is_refund === "0" || refund_type === "1") {
				return index === 0;
			}
			if (refund_type === "2") {
				return true;
			}
		},
	},
	filters: {
		getRefundText(status) {
			if (status === "1") {
				return "退款";
			} else if (status === "2") {
				return "退货退款";
			} else if (status === "3") {
				return "退货";
			} else {
				return "未维权";
			}
		}
	},
	beforeDestroy() {
		window.onresize = null;
	},
	mounted() {
		this.getCloneColumns();
		this.getTotalWidth();
		setTimeout(() => {
			this.handleResize();
		}, 500);
		// 监听窗口的变化
		window.addEventListener("resize", () => {
			this.handleResize();
		});
	}
};
</script>

<style scoped lang="scss">
.order-table-list {
	background-color: #ffffff;
	position: relative;

	.order-table-list-box {
		padding: 0 20px 20px 20px;
		margin-bottom: -2px;

		// 表头
		>.table-header {
			height: 64px;
			/*box-sizing: border-box;*/
			border-bottom: 1px solid $border-color;

			/*margin-bottom: 20px;*/
			>table {

				/*width: 100%;*/
				>tr {

					/*width: 100%;*/
					>th {
						>.table-cell {
							@include font-14-20-bold;
							color: $text-first;
							padding: 20px 15px;
							box-sizing: border-box;
							text-align: left;
						}
					}
				}
			}
		}

		// 表格内容
		>.table-content {
			border-top: 1px solid $border-color;
			border-left: 1px solid $border-color;
			border-radius: 2px;
			margin-top: 20px;

			// 订单信息
			/deep/ .table-order {
				>table {

					/*width: 100%;*/
					>tr {

						/*width: 100%;*/
						>td {
							@include font-14-20;
							color: $text-first;
							padding: 20px 15px;
							border-right: 1px solid $border-color;
							border-bottom: 1px solid $border-color;

							// 价格、数量
							>.shop-price-number {
								@include font-14-20-bold;
							}

							// 维权
							.shop-status {
								.refund-status-box {
									padding: 10px 0;
								}

								/deep/ .status-text {
									.status-text-size-default {
										font-size: 14px;
										font-weight: bold;
									}
								}

								.refund-status-text {
									padding: 2px 6px;
									background-color: $danger-light;
									border-radius: 2px;
									color: $danger-color;
									margin-bottom: 10px;
								}
							}

							//实付款
							.shop-payment {
								>.shop-payment-price {
									@include font-14-20-bold;
								}

								.label {
									@include font-14-20;
									color: $danger-color;
									padding: 2px 6px;
									background-color: $danger-light;
									border-radius: 2px;
									margin-left: 6px;
								}
							}

							// 操作
							>.shop-action {
								&.refund {
									color: #19be6b;
								}

								>.order-status {
									@include font-18-25-bold;
									padding-bottom: 10px;

									// 待发货
									.wait-send-goods {
										color: $warning-color;
									}

									// 待付款
									.wait-payment {
										color: $danger-color;
									}

									// 待收货
									.wait-tack-goods {
										color: $brand-color;
									}

									// 已完成
									.order-archive {
										color: $success-color;
									}

									// 已关闭
									.order-close {
										color: $text-third;
									}
								}

								>.btn {
									>.ivu-btn {
										margin-right: 20px;
									}
								}
							}
						}

					}

					.multi {
						display: flex;

						.shop-image {
							width: 80px;
							height: 80px;
							margin-right: 10px;
							box-sizing: border-box;
							border: 1px solid $border-color;
							border-radius: 2px;
							flex-shrink: 0;
							overflow: hidden;

							.shop-item {
								width: 80px;
								height: 80px;

								img {
									width: 100%;
									height: 100%;
								}
							}
						}
					}

					.brand {
						span {
							border-radius: 2px;
							background-color: $background-color;
							@include font-12-16;
							margin-right: 10px;
							color: $text-second;
							display: inline-block;
							height: 20px;
							line-height: 20px;
							padding: 0 8px;
						}
					}
				}
			}

			// 备注佣金
			/deep/.table-remark {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: center;
				height: 50px;
				padding: 0 20px;
				@include font-14-20;
				border-bottom: 1px solid $border-color;
				border-right: 1px solid $border-color;

				>.left {
					text-align: left;

					>.remark {
						color: $success-color;
						background-color: $success-light;
						border-radius: 2px;
						padding: 2px 8px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

				>.right {
					flex: 1;
					text-align: right;

					.text {
						color: $text-first;
						padding-right: 10px;
					}

					.price {
						color: $danger-color;
					}
				}
			}
		}
	}

	// 分页
	>.table-footer {
		border-top: 1px solid $border-color;
		padding: 20px 0;
		display: flex;
		justify-content: center;
	}
}

.tab-cell-last {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
